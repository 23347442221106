/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Footer from '../components/Footer'
// import Logo from '../images/logo-icon.png'
// import { Container, Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavbarText } from 'reactstrap';

import Header from "./header"
import "./layout.css"

const Layout = ({ path, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} pathName={path} />
      {/* <Container>
        <NavbarBrand className="web_logo">
          <a href="/">
            <div className="logo_left">
              <img src={Logo} />
            </div>
            <div className="logo_right">
              <h2>Trident</h2>
              <p>Trident Consulting</p>
            </div>
          </a>
        </NavbarBrand>
      </Container> */}


      <div>
        <main>{children}</main>
        {/* <footer>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </footer> */}
        <Footer pathName={path} />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
