import React from "react"
import Layout from "../components/layout1"
import Home from './home'
import '../Style/index.css'
import '../Style/audio-player.css'
const IndexPage = (props) => (
  <Layout path="/home/">
    <Home />
  </Layout>
)

export default IndexPage
