// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React, { Component } from 'react'
// import Logo from '../images/logo-icon.png'
import Logo1 from '../images/newLogo.png'
import { CSSTransition } from 'react-transition-group'
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import { MdKeyboardArrowDown } from 'react-icons/md';
class Header extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.state = {
      color: 'white',
      position: 'absolute',
      isOpen: false,
      top: 0,
      width: '100%',
      zIndex: '99',
      showTransition: false,
      dropdownOpen: false,
      btnDropleft: false
    };
  }

  listenScrollEvent = e => {
    if (window.scrollY > 90) { //linear-gradient(to left, rgb(67, 67, 67), rgb(0, 0, 0,1)), boxShadow: 'rgba(0, 0, 0, 1) 0px 1px 12px'
      this.setState({ showTransition: true, position: 'fixed', top: 0, width: '100%', background: '#f7fafb', display: 'initial' })
    } else {
      this.setState({ showTransition: false, position: 'fixed', background: 'none', boxShadow: 'none', display: 'inherit', })
    }
  }
  componentDidMount() {
    window.addEventListener('scroll', this.listenScrollEvent)
  }
  toggle = () => {
    let isOpen = !this.state.isOpen;
    this.setState({ isOpen })
  }
  hoverToggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  onMouseEnter() {
    this.setState({ dropdownOpen: true });
  }

  onMouseLeave() {
    this.setState({ dropdownOpen: false });
  }
  render() {
    console.log(this.props.pathName)

    var pathname = this.props.pathName;
    if (typeof pathname !== 'undefined') {
      pathname = pathname.toLowerCase()
    }

    return (

      <CSSTransition
        classNames='headerTrans'
        in={this.state.showTransition}
        timeout={300}
      >
        <header style={{ position: this.state.position, top: this.state.top, width: this.state.width, background: this.state.background, boxShadow: this.state.boxShadow, display: this.state.display, zIndex: this.state.zIndex, opacity: this.state.headerOpacity }}>
          {/* <Container> */}
          <Navbar light expand="md">
            <NavbarBrand href="/">
              <img src={Logo1} width={90} alt="img" />
              {/* <a href="/">
                  <div className="logo_left">
                    <img src={Logo} />
                  </div>
                  <div className="logo_right">
                    <h2>Trident</h2>
                    <p>Trident Consulting</p>
                  </div>
                </a> */}

            </NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse navbar isOpen={this.state.isOpen}>
              <Nav className="mr_auto" navbar>
                <NavItem>
                  <NavLink href="/" active={pathname === '/home/'}>Home</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/Diversity" active={pathname === '/diversity/'}>Diversity</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/Enterprise" active={pathname === '/enterprise/'}>Enterprise</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/Startups" active={pathname === '/startups/'}>Startups</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/Consultants" active={pathname === '/consultants/'}>Consultants</NavLink>
                </NavItem>
                <NavItem>
                <NavLink href="/Jobs" active={pathname === '/job/'}>Jobs</NavLink>
              </NavItem>
                <NavItem>
                  {/* <NavLink href="/Company" active={pathname === '/Company'}>Company</NavLink> */}
                </NavItem>
                <UncontrolledDropdown  onMouseOver={this.onMouseEnter} onMouseLeave={this.onMouseLeave} isOpen={this.state.dropdownOpen} toggle={this.toggle}
                // isOpen={this.state.isOpen} 
                 onClick={() => window.open("/Company", "_self")} nav inNavbar active={pathname === '/company/' || pathname === '/blog/' || pathname === '/socialresponsibility/' || pathname === '/services/' || pathname === '/aboutceo/' || pathname === '/referfriend/' || pathname === '/referfriend/' || pathname === '/referclient/' || pathname === '/triumphday/' || pathname === '/bigdata/' || pathname === '/certifications/' || pathname === '/contact/'}>
                  <DropdownToggle nav  > Company <MdKeyboardArrowDown /> </DropdownToggle>
                  <DropdownMenu right>
                    {/* <DropdownItem href="/Company" active={pathname === '/company/'} >About Company</DropdownItem>
                    <DropdownItem divider /> */}
                    <DropdownItem></DropdownItem>
                    <DropdownItem href="/Company/Services" active={pathname === '/services/'}>Services</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem href="/Company/WhyTrident" active={pathname === '/whytrident/'}>Why Trident</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem href="/Company/Certifications" active={pathname === '/certifications/'} >Certifications</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem href="/Company/Blog" active={pathname === '/blog/'} >Blog</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem href="/Company/Team" active={pathname === '/team/'}>Team</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem href="/Company/AboutCEO" active={pathname === '/aboutceo/'}>CEO Story</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem href="/Company/SocialResponsibility" active={pathname === '/socialresponsibility/'}>Social Responsibility</DropdownItem>
                    <DropdownItem divider />
                    {/* <DropdownItem href="/Company/ReferFriend" active={pathname === '/referfriend/'}>Refer a Friend</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem href="/Company/ReferClient" active={pathname === '/referclient/'}> Refer a Client</DropdownItem>
                    <DropdownItem divider /> */}
                    {/* <DropdownItem href="/Company/Jobs" active={pathname === '/job/'}>Job Search</DropdownItem>
                    <DropdownItem divider /> */}
                    <DropdownItem href="/Company/ContactUs" active={pathname === '/contact/'}>Contact us</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>
          </Navbar>
          {/* </Container> */}
        </header>
      </CSSTransition>
    )
  }
}
export default Header
